<template>
  <div class="app-box">
    <div class="title-box">
      <div class="line"></div>
      <p class="title">方案列表</p>
    </div>
    <a-divider style="margin-left: -10px" />
    <a-row class="table-action-list" style="margin-top: 20px">
      <a-button type="primary" @click="add" style=" margin-left: 10px">+新增</a-button>
    </a-row>
    <a-table class="main-table" :pagination="{
      current: query.page, total: pagetotal,
      showTotal: (pagetotal, range) => `共 ${pagetotal} 条数据`,
    }" @change="listChange" :columns="columns" :data-source="data" rowKey="plan_id" style="margin-top: 14px" v-viewer>
      <span slot="type" slot-scope="text, record">
        {{ record.type == 1 ? '按时间计费' : '按天数计费' }}
      </span>
      <span slot="is_refund" slot-scope="text, record">
        {{ record.is_refund == 1 ? '已开启' : '已关闭' }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a-space>
          <a @click="toEdit(record)">编辑</a>
          <a-popconfirm title="确认删除该方案" ok-text="是" cancel-text="否" @confirm="del(record.plan_id)">
            <a style="color: #E0352B">删除</a>
          </a-popconfirm>
        </a-space>
      </span>
    </a-table>
    <div>
      <a-modal :title="(toEditIndex === 'addIndex' ? '新增' : '编辑') + '方案'" :visible="visible" ok-text="提交" cancel-text="取消"
        @ok="submitForm" @cancel="handleCancel">
        <a-form-model :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" ref="Form" :model="form" :rules="Rules">
          <a-form-model-item prop="name" label="方案名称：">
            <a-input v-model="form.name"></a-input>
          </a-form-model-item>
          <a-form-model-item prop="type" label="计费方式：">
            <a-radio-group name="radioGroup" v-model="form.type" @change="groupChicke">
              <a-radio :value="1">按时间计费</a-radio>
              <a-radio :value="2">按天数计费</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item prop="is_sound" label="自动退费:">
            <a-switch checked-children="开" un-checked-children="关" v-model="form.is_refund" />
            <a-tooltip placement="topLeft" style="float: right;" arrow-point-at-center overlayClassName="tooltip_class">
              <template slot="title">
                <span>
                  五分钟计费一次，不到五分钟按照五分钟计算如下单20分钟消费10元，使用12分钟后退款；【10-10/(20/5)*3=2.5；自动退款2.5元】
                </span>
              </template>
              <div style="text-align: center;margin-right: 210px;margin-top: -1px;">
                <img src="@/static/icon/tip2.svg" style="width: 18px; height: auto;">
              </div>
            </a-tooltip>
          </a-form-model-item>
          <a-form-model-item label="提示：">
            <a-textarea :rows="2" placeholder="以下时长预测因实际输液速度有所不同，仅供参考，如超时长未输完，请及时续费" v-model="form.describe"></a-textarea>
          </a-form-model-item>
          <div v-show="showJifei">
            <div style="position: relative;" v-for="(item) in form.plan_content" :key="item.price_id">
              <div class="jifei">
                <a-form-model-item prop="time" label="使用时间：">
                  <a-input v-model="item.time"></a-input>
                </a-form-model-item>
                <a-form-model-item prop="money" label="收费金额：">
                  <a-input v-model="item.money" suffix="￥"></a-input>
                </a-form-model-item>
                <a-form-model-item prop="des" label="备注：">
                  <a-textarea :rows="2" v-model="item.des"></a-textarea>
                </a-form-model-item>
              </div>
              <div class="icon_container">
                <img @click="jfEdit(item, index)" src="@/assets/bianji.png" alt="">
                <img @click="jfDel(item, index)" src="@/assets/shanchu.png" alt="">
              </div>
            </div>
          </div>
          <a-button @click="jfAdd" block>添加计费</a-button>
          <!-- <div class="explain">
            <div>1、计费方式:</div>
            <div>按时间计费: 设置使用时间设置对应的金额</div>
            <div>按天数计费: 选择天数设置对应的收费金额</div>
          </div> -->
        </a-form-model>
      </a-modal>
    </div>

    <div>
      <a-modal :title="(jfAtitle === 'Edit' ? '编辑' : '新增') + '计费'" :visible="jfVisible" ok-text="保存" cancel-text="返回"
        @ok="save" @cancel="jifeiCancel">
        <a-form-model :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" ref="jfForm" :model="jfform" :rules="jfRules">
          <a-form-model-item prop="time" label="使用时间：">
            <a-input v-model.number="jfform.time"></a-input>
          </a-form-model-item>
          <a-form-model-item prop="money" label="收费金额：">
            <a-input v-model.number="jfform.money" suffix="￥"></a-input>
          </a-form-model-item>
          <a-form-model-item prop="des" label="备注：">
            <a-textarea :rows="2" v-model="jfform.des"></a-textarea>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>

<script>
import {
  domain
} from '@/siteInfo.js'

export default {
  data() {
    return {
      jfIndex: '',//编辑数组下标
      jfAtitle: '',
      toEditIndex: '',
      query: {
        page: 1,
        limit: 10
      },
      pagetotal: 0,
      hospitals: [],
      plan_id: null,
      visible: false,
      jfVisible: false,
      showJifei: true,
      columns: [{
        title: '序号',
        customRender: (text, record, index) =>
          index + (this.query.page - 1) * this.query.limit + 1
      },
      {
        title: '名称',
        dataIndex: 'name'
      },
      {
        title: '计费方式',
        scopedSlots: {
          customRender: 'type'
        }
      },
      {
        title: '自动退费',
        scopedSlots: {
          customRender: 'is_refund'
        }
      },
      {
        title: '备注',
        dataIndex: 'describe'
      },
      {
        title: '操作',
        scopedSlots: {
          customRender: 'action'
        }
      }
      ],
      data: [],
      form: {
        name: '',
        type: 1,
        is_refund: false,
        describe: '',
        plan_content: []
      },
      Rules: {
        name: [
          {
            required: true,
            message: "请填写方案名称",
            trigger: "blur"
          }
        ]
      },
      jfform: {
        money: '',
        time: '',
        des: '',
      },
      jfRules: {
        time: [{
          required: true,
          message: "请填写使用时间",
          trigger: "blur",
        }],
        money: [{
          required: true,
          message: "请填写收费金额",
          trigger: "blur",
        }],
        des: [{
          required: true,
          message: "请填写备注",
          trigger: "blur",
        }],
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    toEdit(item) {
      this.visible = true
      this.plan_id = item.plan_id
      this.$post(domain + '/admin/v1/hospital/planInfo', { plan_id: item.plan_id }).then(res => {
        if (res.msg == '操作成功') {
          this.form = {
            name: res.data.name,
            describe: res.data.describe,
            type: res.data.type,
            plan_content: res.data.plan_content,
            is_refund: res.data.is_refund === 1
          }
        }
      })
      this.toEditIndex = 'toEditIndex'
      // this.$message.warn('由小土土土开发ui')
    },
    add() {
      this.visible = true
      this.form = {
        name: '',
        type: 1,
        describe: '10元每小时',
        plan_content: [],
        is_refund: false
      }
      this.toEditIndex = 'addIndex'
    },
    handleCancel() {
      this.initForm()
    },
    jifeiCancel() {
      this.jfVisible = false
    },
    initForm() {
      this.visible = false
    },
    submitForm() {
      this.$refs.Form.validate((valid) => {
        if (valid) {
          if (this.form.is_refund == true) {
            this.form.is_refund = 1
          } else {
            this.form.is_refund = -1
          }
          if (this.toEditIndex === 'addIndex') {
            this.$post(domain + '/admin/v1/hospital/planAdd', this.form).then(res => {
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.visible = false
                this.form = {
                  name: '',
                  type: '',
                  describe: '',
                  plan_content: []
                }
                this.getList()
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            let obj = JSON.parse(JSON.stringify(this.form))
            obj.plan_id = this.plan_id
            this.$post(domain + '/admin/v1/hospital/planEdit', obj).then(res => {
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.visible = false
                this.form = {
                  name: '',
                  type: '',
                  describe: '',
                  plan_content: []
                }
                this.getList()
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        }
      })
    },
    del(id) {
      this.$post(domain + '/admin/v1/hospital/planDel', { plan_id: id }).then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getList() {
      this.$post(domain + '/admin/v1/hospital/planList', this.query).then(res => {
        if (res.code === 0) {
          this.data = res.data.list
          this.pagetotal = res.data.total
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    listChange(e) {
      this.query.page = e.current
      this.getList();
    },
    jfEdit(obj, index) {
      this.jfVisible = true
      this.jfIndex = index
      this.jfform = obj
      this.jfAtitle = 'Edit'
    },
    jfDel(obj, index) {
      this.form.plan_content.splice(index, 1)
    },
    jfAdd() {
      this.jfVisible = true
      this.jfform = {
        money: '',
        time: '',
        des: '',
        type: 1
      }
      this.jfAtitle = 'Add'
    },
    //计费保存和新增
    save() {
      if (this.jfAtitle === 'Edit') {
        this.form.plan_content[this.jfIndex] = this.jfform
        this.jfVisible = false
      } else {
        this.$refs.jfForm.validate((valid) => {
          if (valid) {
            this.form.plan_content.push(this.jfform)
            this.jfVisible = false
          }
        });
      }
    },
    //切换按钮事件
    groupChicke(e) {
      this.form.type = e.target.value
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .ant-modal-body {
  padding: 24px 66px;
  height: 75vh;
  overflow-y: scroll;
}

.title-box {
  display: flex;
  align-items: center;
  margin-left: 8px;

  .line {
    width: 5px;
    height: 14px;
    background-color: #4E80F8;
  }

  .title {
    color: #383F50;
    font-size: 16px;
    margin-left: 5px;
  }
}

.img-qr {
  height: 30px;
  cursor: pointer;
}

.jifei {
  border: 1px solid gray;
  border-radius: 12px;
  padding: 20px 16px 0;
  margin-bottom: 20px;
}

.icon_container {
  position: absolute;
  top: 10px;
  right: -55px;

  &>img {
    width: 20px;
    height: 20px;
    margin-left: 6px;
    cursor: pointer;
  }
}

.explain {
  color: red;
  margin-top: 36px;
}
</style>

<style lang="less">
.form-search {
  .ant-form-item-label {
    width: 70px;
  }
}
</style>


<style lang="less">
.tooltip_class {
  max-width: 300px;
}
</style>